var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-card',{attrs:{"flat":"","container-class":"pa-0 table-like-row table-b d-flex align-center"},on:{"click":function($event){_vm.showModal = true}}},[_c('base-row',{attrs:{"no-gutters":""}},[_c('base-col',{staticClass:"px-4",staticStyle:{"min-height":"64px"},attrs:{"cols":"8"}},[_c('base-container',{attrs:{"vertical-center":""}},[_c('base-row',{attrs:{"no-gutters":""}},[_c('base-col',{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('base-container',{attrs:{"vertical-center":""}},[(_vm.item.selectable)?_c('base-check-box',{model:{value:(_vm.item.selected),callback:function ($$v) {_vm.$set(_vm.item, "selected", $$v)},expression:"item.selected"}}):_vm._e(),_c('base-text',{class:!_vm.item.selectable ? 'ml-8' : '',attrs:{"value":_vm.item.user_name}}),_c('group-label',{attrs:{"value":_vm.item.groupno}})],1)],1),_c('base-col',{attrs:{"cols":"6"}},[_c('base-container',{attrs:{"vertical-center":""}},[_c('base-typography',{staticClass:"mr-4",attrs:{"single":""}},[_vm._v(" "+_vm._s(_vm.item.contract_begin.dateStrJp())+" ～ "+_vm._s(_vm.item.contract_end.dateStrJp())+" ")]),(_vm.showExtendIcon)?_c('match-contract-period-icon',{attrs:{"match-contract-period":_vm.item.match_contract_period}}):_vm._e()],1)],1),_c('base-col',{attrs:{"cols":"2"}},[_c('base-container',{attrs:{"vertical-center":""}},[_c('base-text',{attrs:{"value":_vm.item.sales_staff_name}})],1)],1)],1)],1)],1),_c('base-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('base-container',{attrs:{"vertical-center":""}},[_c('base-row',{attrs:{"no-gutters":""}},[_c('base-col',{staticClass:"mr-4",attrs:{"cols":"3"}},[_c('request-status-label',{attrs:{"status":_vm.item.request_status}})],1),_c('base-col',{staticClass:"mx-1",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.item.has_attachment)?_c('base-icon',_vm._g({attrs:{"icon":"far fa-image","color":"primary"}},on)):_vm._e()]}}])},[_vm._v(" ファイルが添付されています ")])],1),_c('base-col',{staticClass:"mx-1",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.item.has_note)?_c('base-icon',_vm._g({attrs:{"icon":"far fa-comment-alt-lines","color":"primary"}},on)):_vm._e()]}}])},[_vm._v(" 補足コメントが入力されています ")])],1),(_vm.showExtendIcon)?[_c('base-col',{staticClass:"mx-1",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.item.has_difference)?_c('base-icon',_vm._g({attrs:{"icon":"far fa-pen","color":"primary"}},on)):_vm._e()]}}],null,false,3281059479)},[_vm._v(" 前回の契約情報から変更があります ")])],1),_c('base-col',{staticClass:"ml-2",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.item.is_customer_decision_needless)?_c('div',_vm._g({},on),[_c('extend-customer-decision-label',{attrs:{"status":_vm.statusNeedless,"text":"確認不要","block":""}})],1):_vm._e()]}}],null,false,2083679394)},[_vm._v(" 延長時に上位会社に確認が不要な案件です ")])],1)]:_vm._e()],2)],1)],1),_c('base-col',{staticClass:"px-4",attrs:{"cols":"12"}},[_c('error-alert',{staticClass:"mt-0",attrs:{"value":_vm.errors}})],1)],1),_c('approval-request-modal',{attrs:{"contract-period-id":_vm.item.contract_period_id,"target-list":_vm.targetList},on:{"statusChanged":_vm.statusChanged},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }